import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="floating-footer">
            <span>ସୌଜନ୍ୟ • କୋହେନୂର୍ ପ୍ରେସ୍ ସଂକ୍ଷେପ ପଞ୍ଜିକା</span>
        </footer>
    );
}

export default Footer;